exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-artist-dirk-bader-js": () => import("./../../../src/pages/artist/dirk-bader.js" /* webpackChunkName: "component---src-pages-artist-dirk-bader-js" */),
  "component---src-pages-artist-fernando-gomez-js": () => import("./../../../src/pages/artist/fernando-gomez.js" /* webpackChunkName: "component---src-pages-artist-fernando-gomez-js" */),
  "component---src-pages-artist-gabriele-di-martino-js": () => import("./../../../src/pages/artist/gabriele-di-martino.js" /* webpackChunkName: "component---src-pages-artist-gabriele-di-martino-js" */),
  "component---src-pages-artist-giannandrea-marongiu-js": () => import("./../../../src/pages/artist/giannandrea-marongiu.js" /* webpackChunkName: "component---src-pages-artist-giannandrea-marongiu-js" */),
  "component---src-pages-artist-ilaria-ieie-js": () => import("./../../../src/pages/artist/ilaria-ieie.js" /* webpackChunkName: "component---src-pages-artist-ilaria-ieie-js" */),
  "component---src-pages-artist-ornella-riverditi-js": () => import("./../../../src/pages/artist/ornella-riverditi.js" /* webpackChunkName: "component---src-pages-artist-ornella-riverditi-js" */),
  "component---src-pages-artist-umberto-gorra-js": () => import("./../../../src/pages/artist/umberto-gorra.js" /* webpackChunkName: "component---src-pages-artist-umberto-gorra-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-edu-js": () => import("./../../../src/pages/edu.js" /* webpackChunkName: "component---src-pages-edu-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */)
}

